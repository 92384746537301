import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15')
];

export const server_loads = [0];

export const dictionary = {
		"/(app)": [~6,[2]],
		"/(app)/account": [~7,[2]],
		"/(app)/admin": [8,[2,3]],
		"/auth/error": [14],
		"/(app)/data-deletion": [9,[2]],
		"/(app)/help": [10,[2]],
		"/login": [~15,[5]],
		"/(app)/privacy": [11,[2]],
		"/(app)/routebuilders": [12,[2,4]],
		"/(app)/routebuilders/[slug]": [13,[2,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,

	reroute: (() => {})
};

export { default as root } from '../root.svelte';